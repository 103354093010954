<template>
  <div>
    <mdb-row class="justify-content-md-center">
      <mdb-col
        class="justify-content-md-center"
        v-if="this.localVendorData.recommended_link.length > 0"
      >
        <mdb-btn
          class="cs-pink text-left pl-1"
          fas
          icon="envelope-open-text"
          iconClass="fa-sm"
          size="sm"
          @click="openLink"
          >{{ this.localVendorData.recommended_link_title }}</mdb-btn
        >
      </mdb-col>
    </mdb-row>
  </div>
</template>
<script>
import { mdbRow, mdbCol, mdbBtn } from "mdbvue";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "CityShoppariRecommended",
  mixins: [helper, api],
  components: {
    mdbRow,
    mdbCol,
    mdbBtn,
  },
  props: {
    vendorData: Object,
  },
  data() {
    return {
      transactionData: "",
      localVendorData: this.vendorData,
    };
  },
  methods: {
    openLink() {
      window.open(this.localVendorData.recommended_link);
    },
  },
  mounted() {},
};
</script>
<style lang=""></style>
